// import components
// =============================================================================
const WelcomePage =  ()=>import('../App/Pages/WelcomePage.vue');
const BaseLayout =  ()=>import('../App/Pages/BaseLayout.vue');
    const ErrorLayout =  ()=>import('../App/Pages/BaseLayout/ErrorLayout.vue');
        const ErrorPage =  ()=>import('../App/Pages/BaseLayout/ErrorLayout/ErrorPage.vue');
        const TokenErrorPage =  ()=>import('../App/Pages/BaseLayout/ErrorLayout/TokenErrorPage.vue');
        const HTTP401Page =   ()=>import('../App/Pages/BaseLayout/ErrorLayout/HTTP401Page.vue');
        const HTTP404Page =   ()=>import('../App/Pages/BaseLayout/ErrorLayout/HTTP404Page.vue');
    const DonatePage =   ()=>import('../App/Pages/BaseLayout/DonatePage.vue');
    const DonateThanksPage =   ()=>import('../App/Pages/BaseLayout/DonateThanksPage.vue');
    const ChangelogPage =   ()=>import('../App/Pages/BaseLayout/ChangelogPage.vue');
    const CreditsPage =   ()=>import('../App/Pages/BaseLayout/CreditsPage.vue');
    const PersonalDataPage =   ()=>import('../App/Pages/BaseLayout/PersonalDataPage.vue');
    const HomeLayout =  ()=>import('../App/Pages/BaseLayout/HomeLayout.vue');
        const AccountPage =   ()=>import('../App/Pages/BaseLayout/HomeLayout/AccountPage.vue');
        const PlansIndexPage =   ()=>import('../App/Pages/BaseLayout/HomeLayout/PlansIndexPage.vue');
        const AdminLayout =  ()=>import('../App/Pages/BaseLayout/HomeLayout/AdminLayout.vue');
            const UsersIndexPage =  ()=>import('../App/Pages/BaseLayout/HomeLayout/AdminLayout/UsersIndexPage.vue');
            const UserShowPage =  ()=>import('../App/Pages/BaseLayout/HomeLayout/AdminLayout/UserShowPage.vue');
            const MailTestingPage =  ()=>import('../App/Pages/BaseLayout/HomeLayout/AdminLayout/MailTestingPage.vue');
            const WeatherStationsPage =  ()=>import('../App/Pages/BaseLayout/HomeLayout/AdminLayout/WeatherStations.vue');
const PlanEditorPage =  ()=>import('../App/Pages/PlanEditorPage.vue');


// define routes
// =============================================================================
const routes = [

    { name: 'demo', path: '/plan/demo', component: PlanEditorPage, meta: {isPlanEditor:true, title: "Editor", noRedirect: true} },
    { name: 'plan-editor', path: '/plan/:id?', component: PlanEditorPage, meta: {isPlanEditor:true, title: "Editor", noRedirect: true} },
    
    // welcome and error page
    { path: '/', component: WelcomePage, meta: {title: "Talaria - Plan your international cycling trips"} },
    
    // the layout with navbar
    { path: '/', component: BaseLayout, children: [

            // public pages (no auth required)
            { path: 'donate', component: DonatePage, meta: {title: "Donate"} },
            { path: 'donate/thanks', component: DonateThanksPage, meta: {title: "Thanks for your donation"} },
            { path: 'changelog', component: ChangelogPage, meta: {title: "Changelog"} },
            { path: 'credits', component: CreditsPage, meta: {title: "Credits"} },
            { path: 'personal-data', component: PersonalDataPage, meta: {title: "Personal data"} },
            { path: '/', component: ErrorLayout, children: [
                { path: 'register', component: HTTP401Page, meta: {authExcluded:true, title: "Register"} },
                { path: 'whoops', component: ErrorPage, meta: {title: "Whoops!"} },
                { path: 'token/error', component: TokenErrorPage, meta: {title: "Token error"} },
            ]},

            // pages for auth users
            { path: 'home', component: HomeLayout, meta: {requiresAuth:true},
                children: [
                    { path: '',  component: PlansIndexPage, meta: {title: "Your travel plans"} },
                    { path: 'account', component: AccountPage, meta: {title: "Preferences"} },
                    { path: 'admin', component: AdminLayout, meta: {requiresAdmin:true}, children: [
                            { path: 'users',  component: UsersIndexPage, meta: {title: "Users (admin)"} },
                            { name: 'users.show', path: 'users/:id',  component: UserShowPage, meta: {title: "User details (admin)"} },
                            { name: 'mail-testing', path: 'mail-testing',  component: MailTestingPage, meta: {title: "Mail testing (admin)"} },
                            { name: 'weather-stations', path: 'weather-stations',  component: WeatherStationsPage, meta: {title: "Weather Stations (admin)"} },
                        ]
                    }
                ]
            },

            // page not found
            { path: '/', component: ErrorLayout, children: [
                { path: '*', component: HTTP404Page }, // must come last of all
            ]},
        ]
    },
]

export default routes;
