// toggles a remote dropdown menu
import { createPopper } from '@popperjs/core';
import $ from "jquery";

let poppingTarget; // the dropdown which is on the process of being displayed
let activeTargets = []; // displayed dropdowns (List should actually have one item at most. This serves for optimizations.)

// returns the target of a dropdown toggle
function togglerGetTarget(togglerElem){
  // case of a specified target
  let selector = togglerElem.getAttribute("dropdown-toggle");
  if ( selector && selector!=='undefined' ){
    return $( selector )[0];
  }

  // default target (a bit tricky)
  return $( $(togglerElem).parentsUntil(".dropdown")[0] || togglerElem ).parent().find('.dropdown-menu')[0];
}

// shows the dropdown menu
function popup(togglerElem){
  let targetElem = togglerGetTarget(togglerElem);
  popoffAllButTarget(targetElem);
  poppingTarget = targetElem;
  togglerElem._popper = createPopper(togglerElem, targetElem, {
    placement: 'bottom-start',
  });
  targetElem.classList.add("show");
  activeTargets.push(targetElem);
}

// hides the dropdown menu
function popoff(togglerElem){
  if ( togglerElem._popper ){
    let targetElem = togglerGetTarget(togglerElem);
    targetElem.classList.remove("show");
    togglerElem._popper.destroy();
    togglerElem._popper = null;
    activeTargets = activeTargets.filter(dropdown=>dropdown!=targetElem);
  }
}

// hides all dropdown menus (but provided exceptionTarget)
function popoffAllButTarget(exceptionTarget){
  if ( activeTargets.length ){
    $("[dropdown-toggle]").each((index, togglerElem)=>{
      if ( activeTargets.length && exceptionTarget != togglerGetTarget(togglerElem) ){
        popoff(togglerElem);
      }
    });
  }
  poppingTarget = null;
}

// add a listener on "Escape" key
window.addEventListener("click", e=>{
  popoffAllButTarget(poppingTarget);
});

// other parts of the app can ask for dropdowns disposal
app.on("destroy-dropdowns", ()=>{
  popoffAllButTarget();
})

// export the directive itself
export default {
  inserted: function(togglerElem, binding) {
    
    togglerElem.setAttribute("dropdown-toggle", binding.value);
    
    togglerElem.addEventListener('click', event=>{
      if ( togglerElem._popper ){
        popoff(togglerElem);
      }
      else {
        popup(togglerElem);
      }
    });
  }
};
