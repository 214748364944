
import UrlParser from '../../libs/api-router/UrlParser';
import ApiIo     from "../../libs/api-router/ApiIo";
import routes    from '../config/api-routes';

// =============================================================================
/*
// this jQuery plugin will allow cancelling all pending api requests when changing page

import $ from "jquery";

$.xhrPool = [];
$.xhrPool.abortAll = function() {
    $(this).each(function(i, jqXHR) {   //  cycle through list of recorded connection
        jqXHR.abort();  //  aborts connection
        $.xhrPool.splice(i, 1); //  removes from list by index
    });
}
$.ajaxSetup({
    beforeSend: function(jqXHR) { $.xhrPool.push(jqXHR); }, //  annd connection to list
    complete: function(jqXHR) {
        var i = $.xhrPool.indexOf(jqXHR);   //  get index for current connection completed
        if (i > -1) $.xhrPool.splice(i, 1); //  removes from list by index
    }
});
*/
// =============================================================================

app.urls = new UrlParser(routes);

/**
 * Returns an api querier instance.
 * @param  {mixed} args - see ApiIo constructor
 * @return {ApiIo} a thenable object
 */
app.apiDeferred = function(){

    let io = new ApiIo(),
        debug = app.debug,
        cErr = console.error;

    io
        .fail(function(...args) {
            let xhr = args[0]||{},
                statusCode = xhr.status,
                requestSettings = args[3]||{}
            ;
            switch ( statusCode ) {
                case 0:
                    debug && cErr("API: XHR Aborted", xhr.statusText);
                    break;
                case 401:
                    debug && cErr("API: 401 Not authenticated");
                    app.emit("api-http-401");
                    break;
                case 403:
                    debug && cErr("API: 403 Not authorized");
                    app.emit("api-http-403");
                    break;
                case 404:
                    debug && cErr("API: 404 Not found");
                    app.emit("api-http-404");
                    break;
                default:
                    debug && cErr("API: http failure", ...args);
                    app.emit("api-http-default-error");
            }

            if ( statusCode!=0 ){
              app.analytics.trackEvent(
                  'error', 
                  'api-http',
                  requestSettings.method + " " + requestSettings.url + " " + JSON.stringify(requestSettings.data),
                  statusCode
              );
            }
        })

        .error(err => debug && console.warn("API error:", err))

        // .done(data=> console.info("generic apiDone", data));
    ;

    return io;
}

/**
 * Sends a request to the server API, with a simplified syntax.
 * See ApiIo.requestApi signature
 */
app.api = function(...args){
    return app.apiDeferred().requestApi(...args);
}
