import Vue from "vue"; 

import ModalDialog from "App/ModalDialog.vue";
const MainMenu = () => import('App/MainMenu.vue');
const DevConsole = () => import('App/DevConsole.vue');

let nbErrorReportsLeft = 3; // Used to limit the number of js error reports logged with ajax requests

export default {
  components: {
      ModalDialog, DevConsole, MainMenu
  },

  data(){
    return {
      debug: null,
      configReady: null,

      appUser: null,
      authReady: false, // either user data has been loaded (and firewall can be proceeded),

      contactEmail: "talaria@ingirum.net",

      defaultErrorMsg: "Whoops! Something went wrong.",

      showPageLoader: false,
      showMenu: false,

      currentModalDialogComponent: null,
      currentModalDialogProps: {},

      // current values for units
      units_distance:      null, // Km | mi
      units_temperature:   null, // °C | °F

      // default values for units
      units_default_distance:      null, // Km | mi
      units_default_temperature:   null, // °C | °F

      popups: [], // []{component:String, props:Object}
    }
  }, // data

  computed: {
      contact(){
          return `Talaria <${this.contactEmail}>`;
      }
  }, // computed

  watch: {
      authReady(){
          // the firewall (handled by Vue Router) needs auth informations out of this context
          // NB: watching "appUser" isn't enough, 'cause "authReady" can change while "appUser" stays null.
          app.authReady = this.authReady;
      },

      appUser(newVal){
          // the firewall (handled by Vue Router) needs auth informations out of this context
          app.user = newVal;
          app.admin = this.admin;

          // re-implement firewall, for cases of user changing.
          /*
          if (!newVal) {
              // handle http 401 (Unauthenticated) errors
              if ( this.$route.matched.some(record => record.meta.requiresAuth)) { // if matched route requires auth
                  this.$router.push({
                      path: '/register',
                      query: { redirect: this.$route.fullPath }
                  });
              }

              else {
                  app.debug && console.warn("TODO: delegate to http 403 errors controller");
              }
          }
          */
      }
  }, // watch

  methods: {

    /**
     * Changes the key of main router view, in order to reinitialize it
     * @return {void}
     */
    refreshRouterView(){
      // console.trace("refreshRouterView");
      this.$route.meta.key = Math.random(); // 100% resets editor
    },
    
    // Auth
    // -------------------------------------------------------------------

    /**
     * Updates user's data in the app
     * @param  {Object} user
     * @return {void}
     */
    onUserChanged(user) {
      // console.info("onUserChanged", util.unvue(this.appUser), util.unvue(user));
      let self = this,
          oldId = self.appUser ? self.appUser.id : null,
          newId = user ? user.id : null
      ;
      self.appUser = user;
      self.authReady = true;

      // set user preferred units as session units
      // (unless user preferred ones are undefined)
      if ( user ){
        self.units_distance = user.units_distance || self.units_default_distance;
        self.units_temperature = user.units_temperature || self.units_default_temperature;
      }

      /*
      // refresh router view
      if ( newId != oldId ){
        this.refreshRouterView();
      }
      */

      _paq.push(['setUserId', user?user.email:false]); // matomo analytics
    },

    /**
     * Requests for current user's data, then triggers a "user-changed" event.
     * @return {ApiIo}
     */
    async fetchUser() {
      return app.api('auth.status')
        .failures(() => app.emit("user-changed", null))
        .done(data => app.emit("user-changed", data.user || null))
    },


    /**
     * Sends a request to server for logging out of current user
     * @return {void}
     */
    logout(){
      this.$router.push( "/" );
      app.api('auth.logout')
        .done(data=>{
          this.$nextTick(() => {
            this.cheer("Bye!");
            app.emit( "user-changed", null );
          });
        })
      ;
    },


    /**
     * Pings the server (in order to keep session alive)
     * @return {void}
     */
    keepSessionAlive(){
        app.api("auth.keep");
    },


    // -------------------------------------------------------------------

    /**
     * Toggles the display of a page preloader overlay.
     * Display can be forced on or off, with ${forceDisplay} argument.
     * @param  {Boolean} forceDisplay? - default is the contrary of current display
     * @return {void}
     */
    togglePageLoader(forceDisplay){
      let display = forceDisplay === undefined ? this.showPageLoader  : !this.showPageLoader
      this.showPageLoader = display;
    },


    // modal dialogs handling
    // -------------------------------------------------------------------

    /**
     * Registers a new modal component
     * TODO: It would be better if components were added to <App/> instance,
     * instead of making them global components.
     * @param  {String} name        - name of the component, without the trailing "-modal"
     * @param  {mixed} constructor  - component constructor
     * @return {void}
     */
    registerModal(name, constructor){
        Vue.component(name + "-modal", constructor);
    },

    /**
     * Registers a new help modal component
     * @param  {String} name        - name of the component, without the trailing "help-modal"
     * @param  ...args - See this.registerModal
     */
    registerHelp(name, ...args){
        this.registerModal(name + "-help", ...args);
    },


    /**
     * Activates one of the modal dialogs components
     * @param  {String|null} name - pop-up to be displayed (its name, without "-modal" suffix). `null` hides the modal
     * @param {?Object} [props={}] - properties to be passed to the included component.
     * @return {void}
     */
    modal(name, props={}){
        if ( name ) {
            // handle analytics
            app.analytics.trackEvent("dialog", "show", name);

            // append modal suffix
            name += "-modal";
        }
        this.currentModalDialogComponent = name;
        this.currentModalDialogProps = props;
    },


    /**
     * Activates one of the help modal dialogs.
     * @param  {String|null} name - help dialog to be displayed (its name, without "-help-modal" suffix). `null` hides the dialog
     * @return {void}
     */
    help(name, ...args){
      return this.modal(name+"-help", ...args);
    },


    /**
     * Closes current modal dialog
     * @return {void}
     */
    closeModal(){
      this.modal(null);
    },


    // pop-ups handling
    // -------------------------------------------------------------------

    /**
     * Registers a new pop-up component
     * TODO: It would be better if components were added to <App/> instance,
     * instead of making them global components.
     * @param  {String} name        - name of the component, without the trailing "-pop-up"
     * @param  {mixed}  constructor - component constructor
     * @return {void}
     */
    registerPopUp(name, constructor){
        Vue.component(name + "-pop-up", constructor);
    },


    // prepare moving of popups handling in this component
    /**
     * Opens a standard notification pop-up
     * @param  {String} message - message to be displayed
     * @param  {String} design    - info|warning|danger|success
     * @param  {Integer} duration - time before hiding the notification. 0 means manual hiding only (but is not default).
     * @return {void}
     */
    notify(message, design, duration){
        this.popup({ message, design, duration });
    },
    inform(msg, ...args){ this.notify(msg, 'info', ...args) },
    cheer(msg, ...args) { this.notify(msg, 'success', ...args) },
    warn(msg, duration, ...args)  { this.notify(msg, 'warning', duration||6000, ...args) },
    alert(msg, duration, ...args){ 
      this.notify( msg||this.defaultErrorMsg, 'danger', duration||8000, ...args ) 
    },

    /**
     * Opens a new popup.
     * 
     * .popup(settings)
     * @param  {Object} settings  - same as PopUp.vue props
     * @return {void}
     *
     * .popup(contentComponent, settings)
     * @param  {String} contentComponent  - name of the pop-up component
     * @param  {Object} settings  - same as PopUp.vue props
     * @return {void}
     * 
     */
    popup(settings, settings2={}){
      if ( typeof settings == "string" ) {
        settings = Object.assign({}, settings2, { contentComponent: settings })
      }
      settings.id = Math.random();
      this.popups.push(settings);
    },


    /**
     * Closes popup with given index
     * @param  {Integer} popupIndex
     * @return {void}
     */
    popoff(popupIndex){
      let removed = this.popups.splice(popupIndex, 1);
    },


    // API http error handlers
    // -------------------------------------------------------------------

    /**
     * Called when the API responds with a 401 http error
     * @return {void}
     */
    onApiHttp401(){
        app.emit("user-changed", null);
        this.warn("You may not access this ressource. Please make sure you are properly connected.")
    },


    /**
     * Called when the API responds with a 403 http error
     * @return {void}
     */
    onApiHttp403(){
        this.fetchUser(); // their's something weird. Refresh user's data
        this.alert("Sorry. You may not access this ressource.");
    },


    /**
     * Called when the API responds with a 403 http error
     * @return {void}
     */
    onApiHttp404(){
        this.alert("The resource you are requesting could not be found. Sorry.");
    },


    /**
     * Called when the API responds with a http error which doesn't
     * have a specific error handler
     * @return {void}
     */
    onApiHttpDefaultError(){
        this.alert(); // calls default error message
    }

    // -------------------------------------------------------------------

  }, // methods

  created(){

    let self = this;

    // declare event listeners
    // ---------------------------------------------

    // auth
    app.on("user-changed", self.onUserChanged);
    app.on("request-logout", self.logout);
    app.on("api-http-401", self.onApiHttp401);
    app.on("api-http-403", self.onApiHttp403);
    app.on("api-http-404", self.onApiHttp404);
    app.on("api-http-default-error", self.onApiHttpDefaultError);

    // page pre-loader toggling
    app.on("route-change", () => self.togglePageLoader(1));
    app.on("route-changed", () => self.togglePageLoader(0));

    // main menu toggling
    app.on("open-menu", ()=>this.showMenu=1);
    app.on("close-menu", ()=>this.showMenu=0);

    // popups and notifications
    app.on("popup", self.popup);
    app.on("refresh-router-view", this.refreshRouterView)

    // Determine measurement units
    // ---------------------------------------------

    // USA, Liberia, & Myanmar are the only countries officilally user Imperial System. (GB mixes both)
    // Official language in Liberia is English
    // http://worldpopulationreview.com/countries/countries-that-use-imperial/
    // https://www.statista.com/chart/18300/countries-using-the-metric-or-the-imperial-system/

    // Mostly USA & Liberia use °F
    // http://worldpopulationreview.com/countries/countries-that-use-fahrenheit/
    
    let lang = navigator.language; //.substr(0, 2);

    self.units_distance = self.units_default_distance = ['en-US', 'my', 'en-GB'].includes(lang) ? "mi" : "Km"; 
    self.units_temperature = self.units_default_temperature =  ['en-US', 'my'].includes(lang) ? "°F" : "°C";

    // startup
    // ---------------------------------------------
    
    self.showPageLoader = true;
    
    // Fetch from server the global app config and current user's status.
    app.api('startup')

        .failures(() => app.emit("user-changed", null))

        .done(data => {
            app.emit("user-changed", data.user || null);

            if ( data.config ) {
                // cast server conf into client conf
                for ( let key in data.config ){
                    app[ key ] = data.config[key];
                }
                self.debug = app.debug;
                self.configReady = true;

                // startup anaytics
                app.analytics.initTracker(app.matomo_tracker_location, app.matomo_site_id);

                // Setup prop errors logging
                if ( !app.debug ){
                    Vue.config.errorHandler = function(err){
                      if ( nbErrorReportsLeft > 0 ){
                        app.analytics.trackEvent('error', 'js-error', err.toString());
                        nbErrorReportsLeft--;
                      }
                    };
                }
            }

            else {
                self.alert();
                console.error("App settings couldn't be fetched from server");
            }

        })

        .always(() => self.showPageLoader = false )
    ;
    
    // Avoid session expiration
    setInterval(self.keepSessionAlive, 17*60*1000);
  }
}
