import Vue from 'vue';

let evBus = new Vue();
let listenedEvents = []; // helps retrieving the context-specific handlers
let separator = ":";     // for context-specific listener names


/* a debug utily that retrusn all event handlers containing provided name
function pickHandlers(name){
  let found = {};
  for (let evName in evBus._events) {
    if ( evName.indexOf(name)>-1 ){
      found[evName] = evBus._events[evName];
    }
  }
  return found;
}
*/


/**
 * Adds a listener on the global event bus.
 * If a context unique id is provided (contextId)
 * (can be the unique id of a Vue component, a Backbone Model...),
 * the event name is prefixed with this id.
 * @param  {String}   event    - event name
 * @param  {Function} callback
 * @param  {Integer}  contextId? - context id (for context-specific listener)
 * @return {void}
 */
app.on = function(event, callback, contextId="root"){
  event = event + separator + contextId; // append context id to event name
  if ( !listenedEvents.includes(event) ) listenedEvents.push( event );
  evBus.$on(event, callback);
};

/**
 * Same as app.on, except that listener will be removed
 * immediately after first call.
app.once = function(event, callback, contextId="root"){
  let cbOnce = function(...args){ 
    callback.apply(this, args); 
    app.off(event, cbOnce, contextId);
  };
  if (callback) console.info("listeners before once", pickHandlers("user-changed"));
  app.on(event, cbOnce, contextId);
  if (callback) console.info("listeners after once", pickHandlers("user-changed"));
}
 */

/**
 * Removes event listeners.
 * If ${eventTypes} is provided, only listeners with this type (ie. with this name, regardless of the contextId) are removed
 * If a callback is provided, only listeners triggering this callback are removed
 * If a context unique id is provided (contextId), only listeners specific to this context are removed.
 * @param  {String|Array} eventTypes     - event names, without the addition of contextId
 * @param  {Function}     callback?      - allows removing only this handler
 * @param  {Integer}      contextId?     - context id (for context-specific listeners)
 * @return {void}
 */
app.off = function(eventTypes, callback, contextId){
  let evToRemove, // must be undefined by default
      typesFilter = "",
      idFilter = ""
  ;

  if ( eventTypes || contextId ){
    if ( !eventTypes ) eventTypes = "[^\\s\\:]+";
    if ( !(eventTypes instanceof Array) ) eventTypes = [eventTypes];

    if ( eventTypes ) {
      // console.info(eventTypes, typeof eventTypes);
      typesFilter = "^(" + eventTypes.join('|') + ')'
    }
    if ( contextId ) idFilter = contextId + "$";

    evToRemove = listenedEvents.filter(evFullName => {
      // console.info("app.off", eventTypes + "\\"+separator + idFilter);
      return (new RegExp(eventTypes + "\\"+separator + idFilter)).test( evFullName );
    });
  }

  // if (callback) console.info("listeners before off", pickHandlers("user-changed"));
  if ( callback ) evBus.$off(evToRemove, callback);
  // if (callback) console.info("listeners after off", pickHandlers("user-changed"));
  if (!callback) { // wip: this should be more elaborate, in order to remove from list the event names which have no more callbacks
    listenedEvents = listenedEvents.filter(listened=>!evToRemove.includes(listened));
  }
}

/**
 * Triggers provided event. Passes provided arguments (args) to the handlers.
 * All context-specific listeners with provided event name are also triggered.
 * @param  {String}    emitedEvent - name of the event to be triggered
 * @param  {...mixed}  args  - arguments to be passed to the handlers
 * @return {void}
 */
app.emit = function(emitedEvent, ...args){
  listenedEvents
    .filter(evFullName => {
      if (evFullName === emitedEvent) return true; // evFullName matches emitedEvent
      return evFullName.substring(0, emitedEvent.length+1) == emitedEvent+separator; // evFullName matches `${emitedEvent}:`
    })
    .forEach(evFullName => {
      evBus.$emit(evFullName, ...args)
    })
  ;
}


/**
 * Returns the list of all listened events;
 * @return {Array}
 */
app.events = function(){
  return listenedEvents;
}
