// list icons to be used into the app (with Font Awesome SVG+js)

let selectedIcons = [];

// Font Awesome Solid package
// -----------------------------
import {
    faAngleLeft,
    faAngleRight,
    faBars,
    // faBolt,
    faCalendar,
    faChartArea,
    faCheck,
    faCheckCircle,
    faCloudSun,
    faCog,
    faCompress,
    faDownload,
    faEdit,
    faEllipsisV,
    faEnvelope,
    faExclamationTriangle,
    faExpand,
    faEye,
    faFolderOpen,
    faGlobe,
    faHeart,
    faHeartbeat,
    faInfoCircle,
    faListOl,
    // faListUl,
    // faMapMarker,
    faMapMarkerAlt,
    faPaperPlane,
    faMinus,
    faPlus,
    faPlusCircle,
    faQuestionCircle,
    faRuler,
    faRss,
    faUser,
    faUsers,
    faUserCog,
    faUserPlus,
    faSignInAlt,
    faSignOutAlt,
    faSpinner,
    faSave,
    // faShare,
    faStar,
    faStepBackward,
    faStepForward,
    faStopwatch,
    faStreetView,
    faSyncAlt,
    faThermometerHalf,
    faTimes,
    faTrash,
    faWrench,
} from '@fortawesome/free-solid-svg-icons';

selectedIcons = selectedIcons.concat([
    faAngleLeft,
    faAngleRight,
    faBars,
    // faBolt,
    faCalendar,
    faChartArea,
    faCheck,
    faCheckCircle,
    faCloudSun,
    faCog,
    faCompress,
    faDownload,
    faEdit,
    faEllipsisV,
    faEnvelope,
    faExclamationTriangle,
    faExpand,
    faEye,
    faFolderOpen,
    faGlobe,
    faHeart,
    faHeartbeat,
    faInfoCircle,
    faListOl,
    // faListUl,
    // faMapMarker,
    faMapMarkerAlt,
    faPaperPlane,
    faMinus,
    faPlus,
    faPlusCircle,
    faQuestionCircle,
    faRuler,
    faRss,
    faUser,
    faUsers,
    faUserCog,
    faUserPlus,
    faSignInAlt,
    faSignOutAlt,
    faSpinner,
    faSave,
    // faShare,
    faStar,
    faStepBackward,
    faStepForward,
    faStopwatch,
    faStreetView,
    faSyncAlt,
    faThermometerHalf,
    faTimes,
    faTrash,
    faWrench,
]);


// Font Awesome Regular package
// -----------------------------
import {
    faCopy,
    faEyeSlash,
    faMinusSquare,
    faPauseCircle,
    faPlusSquare,
    // faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';

selectedIcons = selectedIcons.concat([
    faCopy,
    faEyeSlash,
    faMinusSquare,
    faPauseCircle,
    faPlusSquare,
    // faTrashAlt,
]);


// Custom package
// -----------------------------

let custom_package_prefix = "fac";

// import TalariaLogo from "../../icons/talaria-logo";
// TalariaLogo.prefix = custom_package_prefix;

selectedIcons = selectedIcons.concat([

    {
      prefix: custom_package_prefix,
      iconName: "ride",
      icon: [
        24, // width
        24, // height
        [], // ligatures
        null, // unicode (if relevant)
        "M15.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM5 12c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 8.5c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5zm5.8-10l2.4-2.4.8.8c1.3 1.3 3 2.1 5.1 2.1V9c-1.5 0-2.7-.6-3.6-1.5l-1.9-1.9c-.5-.4-1-.6-1.6-.6s-1.1.2-1.4.6L7.8 8.4c-.4.4-.6.9-.6 1.4 0 .6.2 1.1.6 1.4L11 14v5h2v-6.2l-2.2-2.3zM19 12c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 8.5c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5z" // svg path data
      ]
    },

    {
      prefix: custom_package_prefix,
      iconName: "hop",
      icon: [
        24, // width
        24, // height
        [], // ligatures
        null, // unicode (if relevant)
        "m 17,8 0,-3 7,7 -7,7 0,-3 4,-4 -4,-4 z m -6,1 0,-4 7,7 -7,7 0,-4.1 C 6,14.9 2.5,16.5 0,20 1,15 4,10 11,9 Z" // svg path data
      ]
    },

    {
      prefix: custom_package_prefix,
      iconName: "map-marker-slash",
      icon: [
        640, // width
        512, // height
        [], // ligatures
        null, // unicode (if relevant)
        "M633.82 458.1L462.41 325.62C502.09 265.52 512 238.3 512 192 512 86.4 425.6 0 320 0c-68.2 0-128.24 36.13-162.3 90.12L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.35 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.42-6.97 4.17-17.02-2.81-22.45zM300.8 502.4c9.6 12.8 28.8 12.8 38.4 0 18.6-26.69 35.23-50.32 50.14-71.47L131.47 231.62c10.71 52.55 50.15 99.78 169.33 270.78z" // svg path data
      ]
    },

    {
      prefix: custom_package_prefix,
      iconName: "map-marker-plus",
      icon: [
        384, // width
        512, // height
        [], // ligatures
        null, // unicode (if relevant)
        "M192 0C86.4 0 0 86.4 0 192c0 76.8 25.6 99.2 172.8 310.4 9.6 12.8 28.8 12.8 38.4 0C358.4 291.2 384 268.8 384 192 384 86.4 297.6 0 192 0zm112 200c0 8.84-7.16 16-16 16h-72v72c0 8.84-7.16 16-16 16h-16c-8.84 0-16-7.16-16-16v-72H96c-8.84 0-16-7.16-16-16v-16c0-8.84 7.16-16 16-16h72V96c0-8.84 7.16-16 16-16h16c8.84 0 16 7.16 16 16v72h72c8.84 0 16 7.16 16 16v16z" // svg path data
      ]
    },

    /*
    {
      prefix: custom_package_prefix,
      iconName: "rabit-fast",
      icon: [
        640/512*576, // width
        512, // height
        [], // ligatures
        null, // unicode (if relevant)
        "M135.52 412.67a15.99 15.99 0 0 0-7.52 13.57v37.74c0 12.57 13.82 20.23 24.48 13.57l66.69-39.87-47.85-47.85-35.8 22.84zm416.76-195.42l-56.42-34.62c-.06-13.95-2.28-30.77-7.07-48.67-11.32-42.24-31.91-73.43-45.99-69.66-14.08 3.77-16.32 41.08-5 83.32.65 2.44 1.44 4.7 2.15 7.06-4.89-6.08-10.23-12.23-16.31-18.32-30.93-30.92-64.35-47.64-74.66-37.33s6.4 43.73 37.33 74.66c12.67 12.67 25.67 22.77 37.42 29.78-3.14 5.76-5.71 12.06-6.89 19.32-.49 3.03-.71 6.15-.75 9.31C364.55 195.11 261.59 128 192 128c-52.08 0-85.21 28.24-104.72 54.09-1.77-2.7-2.96-5.66-5.33-8.03-18.75-18.75-49.14-18.75-67.88 0-18.74 18.74-18.74 49.14 0 67.88 16.4 16.39 41.29 17.57 59.92 5.29l191.4 191.4c6 6 14.14 9.37 22.63 9.37h144c8.84 0 16-7.16 16-16v-16c0-17.67-14.33-32-32-32h-96v-55.59c0-35.53-23.86-67.16-58.02-76.91l-42.38-12.11c-8.5-2.44-13.42-11.3-11-19.78 2.44-8.52 11.41-13.33 19.78-11l42.38 12.11C318.59 234.38 352 278.66 352 328.41V352l64-32h103.35c31.29 0 56.65-25.36 56.65-56.65a56.66 56.66 0 0 0-23.72-46.1zM496 256c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16z" // svg path data
      ]
    },*/

    {
      prefix: custom_package_prefix,
      iconName: "tachometer-fast", // tachometer-fast
      icon: [
        640/512*576, // width
        512, // height
        [], // ligatures
        null, // unicode (if relevant)
        "M288 32C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm163.2 174.41L342.65 351.14c5.81 9.63 9.35 20.79 9.35 32.86 0 11.72-3.38 22.55-8.88 32H232.88c-5.5-9.45-8.88-20.28-8.88-32 0-35.35 28.65-64 64-64 5.65 0 11.02.96 16.24 2.34L412.8 177.59c7.98-10.56 23-12.72 33.61-4.8 10.59 7.96 12.75 23.01 4.79 33.62z" // svg path data
      ]
    },

    {
      prefix: custom_package_prefix,
      iconName: "mountain-plus",
      icon: [
        640, // width
        640, // height
        [], // ligatures
        null, // unicode (if relevant)
        "M 505.83984 0 C 496.04219 0 485.38477 7.9464907 485.38477 17.744141 L 485.38477 93.791016 L 408.25195 93.791016 C 398.45429 93.791016 390.50781 105.53438 390.50781 115.33203 L 390.50781 133.07422 C 390.50781 142.87187 398.45429 155.69922 408.25195 155.69922 L 485.38477 155.69922 L 485.38477 230.66406 C 485.38477 240.46171 496.04219 248.40625 505.83984 248.40625 L 523.58398 248.40625 C 533.38164 248.40625 544.51114 240.46145 544.58203 230.66406 L 544.58203 155.69922 L 621.17188 155.69922 C 630.96947 155.69922 638.91602 142.87187 638.91602 133.07422 L 638.91602 115.33203 C 638.91602 105.53438 630.96947 93.791016 621.17188 93.791016 L 544.58203 93.791016 L 544.58203 17.744141 C 544.58203 7.9464907 533.38164 0 523.58398 0 L 505.83984 0 z M 320 128 C 309.11 128 298.97008 133.53922 293.08008 142.69922 L 5.0800781 590.69922 A 32.001 32.001 0 0 0 3.9101562 623.33984 A 32.004 32.004 0 0 0 32 640 L 608 640 C 619.71 640 630.47984 633.61008 636.08984 623.33008 A 31.983 31.983 0 0 0 634.91992 590.69922 L 346.91992 142.69922 C 341.02992 133.53922 330.89 128 320 128 z M 320 219.17969 L 405.39062 352 L 320 352 L 256 416 L 217.93945 377.93945 L 320 219.17969 z" // svg path data
      ]
    },

    /*
    {
      prefix: custom_package_prefix,
      iconName: "mountain-minus",
      icon: [
        640, // width
        640, // height
        [], // ligatures
        null, // unicode (if relevant)
        "m 485.38477,43.892711 h -50.01418 c -9.79766,0 -17.74414,11.743364 -17.74414,21.541014 v 17.74219 c 0,9.79765 7.94648,22.624995 17.74414,22.624995 h 50.01418 59.19726 76.58985 c 9.79759,0 17.74414,-12.827345 17.74414,-22.624995 v -17.74219 c 0,-9.79765 -7.94655,-21.541014 -17.74414,-21.541014 H 544.58203 Z M 320,128 c -10.89,0 -21.02992,5.53922 -26.91992,14.69922 l -288.0000019,448 c -6.3309924,9.84717 -6.7796794,22.36546 -1.1699219,32.64062 C 9.5221561,633.61227 20.294557,640.00141 32,640 h 576 c 11.71,0 22.47984,-6.38992 28.08984,-16.66992 5.60982,-10.27171 5.1611,-22.78727 -1.16992,-32.63086 l -288,-448 C 341.02992,133.53922 330.89,128 320,128 Z m 0,91.17969 L 405.39062,352 H 320 l -64,64 -38.06055,-38.06055 z" // svg path data
      ]
    },
    */

    {
      prefix: custom_package_prefix,
      iconName: "arrow-from-left",
      icon: [
        448, // width
        512, // height
        [], // ligatures
        null, // unicode (if relevant)
        "M0 424V88c0-13.3 10.7-24 24-24h24c13.3 0 24 10.7 24 24v336c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zm254.5-269.6l65.6 65.6H120c-13.3 0-24 10.7-24 24v24c0 13.3 10.7 24 24 24h200.1l-65.6 65.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L441 273c9.4-9.4 9.4-24.6 0-33.9L305.5 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6-.1 33.9z" // svg path data
      ]
    },

    {
      prefix: custom_package_prefix,
      iconName: "arrow-to-right",
      icon: [
        448, // width
        512, // height
        [], // ligatures
        null, // unicode (if relevant)
        "M448 88v336c0 13.3-10.7 24-24 24h-24c-13.3 0-24-10.7-24-24V88c0-13.3 10.7-24 24-24h24c13.3 0 24 10.7 24 24zm-280.5 66.4l65.6 65.6H24c-13.3 0-24 10.7-24 24v24c0 13.3 10.7 24 24 24h209.1l-65.6 65.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L353.9 273c9.4-9.4 9.4-24.6 0-33.9L218.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9z" // svg path data
      ]
    },
    
    /*
    {
      prefix: custom_package_prefix,
      iconName: "detour",
      icon: [
        512, // width
        512, // height
        [], // ligatures
        null, // unicode (if relevant)
        "m 338.91016,1.6054688 c -8.48823,0 -16.39144,6.6361153 -16.58399,16.5234372 V 120.82617 H 212.6582 v 98.89258 h 109.66797 v 102.69727 c 0,9.88919 8.09576,16.52148 16.58399,16.52148 3.85268,0 7.78953,-1.35903 11.08593,-4.4082 L 505.48828,182.38672 c 7.06667,-6.53099 7.06667,-17.69751 0,-24.22852 L 349.99609,6.013672 C 346.69969,2.985117 342.76284,1.6054687 338.91016,1.6054688 Z M 15.208984,95.35352 c -8.283928,8.3e-4 -14.999172,6.71607 -15,15 v 125.72656 c 8.28e-4,8.28393 6.716072,14.99917 15,15 H 142.7832 c 8.28393,-8.3e-4 14.99917,-6.71607 15,-15 V 110.35352 c -8.3e-4,-8.28393 -6.71607,-14.99917 -15,-15 z m 13.404297,207.51562 v 192.42383 c 0,9.1063 7.376114,16.48242 16.482422,16.48242 h 65.927737 c 9.1063,0 16.48242,-7.37612 16.48242,-16.48242 V 302.86914 Z" // svg path data
      ]
    },
    */

]);

export default selectedIcons;
