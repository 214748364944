const units = {
  // Units that serve of reference in parameters
  // (Preferred units aren't customized individualy.
  // From distance and temperature settings are
  // determined the other preferences.)
  refUnit: ["Km", "°C"],

  /*
  type2defaultUnit: {
    distance:       "Km",
    speed:          "Km/h",
    elevation:      "m",
    precipitations: "mm",
    temperatures:   "°C"
  },
  */

  // Reference units corresponding to featured units
  conversions: {
    mi:     "Km",   // distances
    mph:    "Km/h", // speed 
    ft:     "m",    // elevation
    in:     "mm",   // climate (precipitations)
    "°F":   "°C",   // climate (temperatures)
    "%":    "%",    // climate (humidity)
    Days:   "Days", // climate (humidity)
    Hr:     "Hr",   // climate (light)

    // reference units are known, but don't convert
    Km:     false,  // distances
    "Km/h": false,  // speed 
    m:      false,  // elevation
    mm:     false,  // climate (precipitations)
    "°C":   false,  // climate (temperatures)
  },

  // Number of decimals to display for values of each unit
  decimals: {
    Km:   0,   // distances
    mi:   0,   // distances
    "Km/h": 0, // speed 
    mph:    0, // speed
    m:    0,   // elevation
    ft:   0,   // elevation
    mm:   0,   // climate (precipitations)
    in:   1,   // climate (precipitations)
    "°C": 1,   // climate (temperatures)
    "°F": 0,   // climate (temperatures)
    "%":  0,   // climate (humidity)
    Days: 0,   // climate (precipitation)
    Hr:   0,   // climate (light)
  },

}

export default units;
