import config from "[config]/units";

const unitUtils = {
  /**
   * Generic unit utilities
   * ============================================
   */

  /**
   * Returns either provided units are part
   * of the "temperature" or "distance" category.
   * (These categories are referred in the 
   * conversion settings.)
   * @param  {String} unit - abbreviated unit name
   * @return {String} - category name (temperature|distance)
   */
  category(unit){
    return ['°C', '°F'].includes(unit)
      ? "temperature"
      : "distance"
    ;
  },


  /**
   * Returns the standard units in which provided units convert.
   * Standard units inputs convert to themselves.
   * @param  {String} destUnit - abbreviated units
   * @return {String|false} - abbreviated units
   *                        - false in case provided units aren't registered
  convertsTo(destUnit){
    let destUnit = config.conversions[ destUnit ];
    if ( destUnit === false ) destUnit = destUnit;
    return destUnit || false;
  },
   */

  /**
   * Returns the alternative refUnit (ie. imperial) for
   * provided reference refUnit (ie. metric system)
   * @param  {String} refUnit
   * @return {String|false} - reference unit abbreviation
   *                        - false in case of unregistered unit
   */
  altUnit(refUnit){
    for (let [alt, ref] of Object.entries(config.conversions)) {
      if ( refUnit == ref ) return alt;
    }
    return false;
  },


  /**
   * Returns which are the default units for provided units.
   * @param  {String} units - abbreviated units
   * @return {String|false} - abbreviated units
   *                        - false in case provided units aren't registered
   */
  refUnit(units){
    const conversions = config.conversions;
    for ( let destUnits in conversions ){
      if ( units == destUnits ){
        return conversions[destUnits] 
               || destUnits // case of units which are already default
        ;
      }
    }
    // case provided units aren't registered
    return false;
  },


  /**
   * Converts to provided destination units.
   * Source units are guessed from destination units.
   *
   * convert( destUnits, value )
   * Returns value, after converting it into destination units
   * @param  {String} destUnits
   * @param  {Float}  value
   *
   * convert( destUnits, value, pattern )
   * Returns value and units, after converting them into destination units
   * @param  {String} destUnits
   * @param  {Float}  value
   * @param  {String} pattern - see this.format()
   */
  convert(destUnits, value, pattern=null){
    // convert value, if it is a number, not already in destination units
    if ( (value || value===0) && destUnits!=this.refUnit(destUnits) ){
      switch ( destUnits ){
        case "ft":
          value *= 3.2808;
          break;
        case "in":
          value /= 25.4;
          break;
        case "mi":
        case "mph":
          value *= 0.621371192;
          break;
        case "°F":
          value = value * 9/5 + 32;
          break;
      }
    }

    if ( pattern===null ){
      return value;
    }

    // parse format
    return this.format(value, destUnits, pattern);
  },

  /**
   * Formats provided value, following provided
   * unit rules (for decimals) and pattern.
   * @param  {mixed} value
   * @param  {String} unit
   * @param  {String} pattern
   * @return {String}
   */
  format(value, unit, pattern){
    // handle special values
    if ( value == Infinity ) value = false;
    if ( !value && value!==0 ){
      if ( value === false ) return "?" + (app.debug ? " "+value : "");   // error
      return "-" + (app.debug ? " "+value : "");
    }

    // default unit and format
    if ( unit === undefined ) pattern = pattern || ":r";
    pattern = pattern || ":r :u";

    // final formatting
    return pattern
      .replace(":r", util.round(value, config.decimals[unit]||0).toLocaleString(undefined, {minimumFractionDigits: config.decimals[unit]}))
      .replace(":u", unit)
      .replace(":v", value.toLocaleString(undefined, {minimumFractionDigits: config.decimals[unit]}))
  },


  /**
   * ============================================
   */

   /**
   * Returns a ready-to-print distance.
   * @param  {Float} distance
   * @return {Integer|String}
  distance(distance, unit){
    return this.format(unit, distance);
  }
   */


  /**
   * Rounds and returns provided latitude or longitude
   * @param  {Float} latOrLng
   * @return {Float}
   */
  roundLatLng(latOrLng) {
    return this.round( latOrLng, 5 );
  },

  /**
   * Returns a percentage value.
   * Null values are printed "-".
   * @param  {Float} value - 1=100%
   * @return {Integer|String}
   */
  percent(value) {
    return this.format(value*100, "%", ":r:u");
  },


};


export default unitUtils;
