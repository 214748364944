<template>
  <li
    class="pop-up alert m-3 alert-opaque"
    :class="['alert-'+design]"
    @click.stop="cancelTimeout"
    @mouseover="suspendTimeout"
    @mouseout="resetTimeout"
  >
    <div v-if="withTimeout" class="d-flex mb-2">
      <div class="timespent"></div><div ref="timeleft" class="timeleft bg-light"></div>
    </div>

    <button type="button" class="btn-close float-end ms-2" @click.stop="close"></button>

    <div v-if="message" v-html="message"></div>
    <pre-loader v-if="!message && waitingComponent" message="" />
    <component v-show="!message && !waitingComponent" ref="child" :is="fullComponentName" v-bind="contentProps" @close="close" />
  </li> <!-- .pop-up -->
</template>

<style lang="css" scoped>
  .pop-up {
    min-width: 300px;
    max-width: 500px;
    list-style: none;
  }

  .timespent {
    height: 2px;
    flex: 1;
  }

  .timeleft {
    height: 2px;
    /*width: 100%;*/
  }

  .close {
    color: white;
    opacity: .85;
  }
</style>

<script>
  /** For display of pop-ups */
  import $ from "jquery";

  export default {

    props: {
      message: String,

      design: {
        type: String,
        default: "info"
      },
      duration: {
        type: Number,
        default: 3500
      },

      contentComponent: String,
      contentProps: {
        type: Object,
        default(){ return {} }
      }
    }, // props,

    data() {
      return {
        waitingComponent: true, // controls the display of the preloader
        delay: null,            // locally changeable equivalent for "duration" prop
        timeout: null,
        withTimeout: null
      }
    }, // data

    computed: {
      fullComponentName(){
        return this.contentComponent ? this.contentComponent+'-pop-up' : null;
      }
    }, // computed

    methods: {
      // requests destruction of this component
      close(){
        this.$emit("close");
      },

      /**
       * Resets the closing timeout
       * @return {void}
       */
      resetTimeout(){
        let self = this;
        
        if ( self.withTimeout ){
          self.delay = self.duration;
          clearTimeout(self.timeout);

          self.$nextTick(()=>{
            if ( self.delay ) {
              $( self.$refs.timeleft )
                .css({ width: '100%' })
                .animate({ width: '0%' }, self.delay, "linear")
              ;
              self.timeout = setTimeout(()=>{
                if (self.delay) {
                  self.close();
                }
              }, self.delay);
            }
          });
        }

      },

      /**
       * Suspends the closing timeout
       * @return {void}
       */
      suspendTimeout(){
        this.delay=0;
        $( this.$refs.timeleft ).stop();
      },


      /**
       * Completely cancles popup timeout
       * @return {void}
       */
      cancelTimeout(){
        this.suspendTimeout();
        clearTimeout(this.timeout);
        this.withTimeout = false;
      },


      /**
       * Repeatingly checks either included component is ready.
       * Hides pre-loader once test is a success. 
       * @return {void}
       */
      waitComponent(){
        if ( this.$refs.child && this.$refs.child._isMounted ){
          this.waitingComponent = false;  
        }
        else {
          this.waitingComponent = setTimeout(this.waitComponent, 100);
        }
      }
    },

    created(){$
      this.withTimeout = !!this.duration;
      this.waitComponent(); // asks to hide pre-loader when included component is ready
    },

    mounted(){
      this.resetTimeout();
    }

  }
</script>
