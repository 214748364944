import Vue from 'vue';
import boot from "./app/boot";

// declare app root component
// =============================================================================

import AppTemplate from './app/App.vue';// import dummy_var_bootstrap from "bootstrap" // Twitter Bootstrap; 338
import 'bootstrap/js/dist/dropdown'; // https://getbootstrap.com/docs/5.0/customize/optimize/#lean-javascript

app.vue = new Vue({
    el: "#vue-root",
    render: h => h(AppTemplate),
    router: boot.router
})

if (module.hot) { module.hot.accept(); }