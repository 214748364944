

// import and setup Vue Router
// =============================================================================

import $ from 'jquery';
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from "../config/routes";

Vue.use(VueRouter)
let router = new VueRouter({
    mode: 'history',
    routes
});


// firewall
let waitForAuth = new Promise(function(resolve, reject) { // no firewall resolving before we have user authentication check proceeded (see app.js:fetchUser())
    function cycleCheck(){
        if ( app.authReady ) resolve();
        else setTimeout(cycleCheck, 50);
    }
    cycleCheck();
});
router.beforeEach((to, from, next) => {
    // once we have auth status, perform firewall checks
    waitForAuth.then(
        () => {
            let nextParam;

            // Routes requiring auth (401 handling)
            if ( !app.user && to.matched.some(record => record.meta.requiresAuth) ) {
                nextParam = {
                    path: '/register',
                    query: { redirect: to.fullPath }
                };
            }

            // Some pages are not meant to be visited by auth users
            if ( app.user && to.matched.some(record => record.meta.authExcluded) ) {
                nextParam = '/home'; // auth user go home
            }

            // Routes requiring admin permissions (403 handling)
            if ( !app.admin && to.matched.some(record => record.meta.requiresAdmin) ) {
                nextParam = {
                    path: '/page-not-found', // treated a 404 error
                    query: { redirect: to.fullPath }
                };
            }

            // final next()
            if ( nextParam ) next(nextParam);
            else next();
        },
        () => next("/whoops")
    );
});

router.beforeEach((to, from, next) => {
    // for performance reasons, abort all pending jqXHR and Queuer items before changing page
    app.queuer && app.queuer.cancel();
    // $.xhrPool.abortAll();

    app.emit("route-change");

    next();
});

router.afterEach((to, from, next)=>{
    // customize page title
    let title = to.meta.title || "";
    if ( title ) title += " - ";
    title += "Talaria";
    document.title = title;

    app.emit("route-changed");

    // matomo analytics
    // setCustomUrl( string ) - Override the page's reported URL
    _paq.push(['setCustomUrl', to.fullPath]);
    _paq.push(['trackPageView', title]);
});

export default router;
