export default {
    "startup":                  "/api/startup",     // app settings + user status

    "auth.login":          "POST /api/auth/login",  // login
    "auth.status":              "/api/auth/status", // connected user data
    "auth.logout":              "/api/auth/logout", // disconect user
    "auth.keep":                "/api/auth/keep",
    "auth.password-forgotten": "POST /api/auth/password-forgotten", // ask a connexion token

    "account.save":        "POST /api/account",
    "account.close":            "/api/account/close", // erase one's account

    "plans.save":          "POST /api/plans",
    "plans.rename":        "POST /api/plans/:id/rename",
    "plans.index":              "/api/plans",
    "plans.get":                "/api/plans/:id",
    "plans.delete":             "/api/plans/:id/delete",
    "plans.duplicate":          "/api/plans/:id/duplicate",

    "users.index":              "/api/users/index",
    "users.get":                "/api/users/:id",

    "mail-test.send":      "POST /api/mail-test",
    "mail-test.defaults":       "/api/mail-test/defaults",

    "weather-stations":         "/api/weather-stations",

    "api.section":              "/api/editor/section",
    "api.location-info":        "/api/editor/location-info",
    "api.climate":              "/api/editor/climate-stats",
    

}
