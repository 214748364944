// @see https://vuejs.org/v2/guide/plugins.html#ad
import util from "util";
import md5 from "md5";

import PopUp from "./PopUp.vue";
const AuthWidget = () => import("./AuthWidget.vue");
const NavBar = () => import("./NavBar.vue");
const PreLoader = () => import("./PreLoader.vue");
const AuthForms = () => import("App/talaria-plugin/AuthForms.vue");
const AuthDialog = () => import("App/talaria-plugin/AuthDialog.vue");
const FlagIcon = () => import("App/talaria-plugin/FlagIcon.vue");
const ErrorMessage = () => import("App/Pages/BaseLayout/ErrorLayout/ErrorMessage.vue");
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import escapableDirective from "App/talaria-plugin/escapable-directive";
import DropdownToggleDirective from "App/talaria-plugin/dropdown-toggle-directive";

// Prepare some general utilities from "util" object as vue filters
let utilFilters = {};
"round duration date2str dateAgo percent".split(" ").forEach(function(fnName){
  utilFilters[fnName] = function(...args) {
  return util[fnName].apply(util, args);
  };
});

// some elements of configuration
import unitSettings from "[config]/units";

/**
 * Returns a unique id used in events listening.
 * @param  {vNode} vueComponent
 * @return {void}
 */
function evListeningId(vueComponent){
  return "vc-" + (vueComponent.$options._componentTag||"x") + "-" + vueComponent._uid;
}

let TalariaPlugin = {
  install(Vue, options) {

    // global components
    // ----------------------------------

    Vue.component('pop-up',        PopUp);
    Vue.component('nav-bar',       NavBar);
    Vue.component("pre-loader",    PreLoader);
    Vue.component('fa-icon',       FontAwesomeIcon);
    Vue.component('flag-icon',     FlagIcon);
    Vue.component('auth-widget',   AuthWidget); 
    Vue.component('auth-forms',    AuthForms); 
    Vue.component('auth-modal',    AuthDialog);
    Vue.component('error-message', ErrorMessage);

    // global directives
    // ----------------------------------
    // https://vuejs.org/v2/guide/custom-directive.html

    // v-focus: gives focus to an element on component insersion
    Vue.directive('focus', {
      inserted: function(el, binding) {
        if (binding.modifiers.select) el.select();
        else if ( binding.value || binding.value===undefined ) el.focus()
      }
    });

    // fixes browser password managers ignoring autocomplete="off
    Vue.directive('pwd-fix', {
      inserted: function(el, binding) {
        el.setAttribute("readonly", true);
        el.addEventListener("focus", function() {
          setTimeout(() => el.removeAttribute('readonly'), 100); // timeout is required for keyboard navigation into the form
        });
        el.addEventListener("blur", function() {
          el.setAttribute('readonly', true);
        });
        // el.classList.add('readonly-fake');
        el.classList.add("bg-white");
      }
    });

    // shorthand for :href="'mailto:' + contact"
    Vue.directive('mailto', {
      inserted: function(el, binding) {
        el.setAttribute("href", `mailto:${binding.value}`);
      }
    });

    // shorthand for :href="'mailto:' + contact"
    Vue.directive('escapable', escapableDirective);

    /* WIP (maybe not so useful)
    // short hand for bootstrap display classes
    Vue.directive('display', {
      inserted: function(el, binding) {
        let defaultDisplay = "block",
          value = binding.value;

        // default non "none" displays are declared as boolean modifiers
        ["block", "inline", "flex", "inline-flex", "table-cell"].forEach(disp=>{
          if (binding.modifiers[disp]) defaultDisplay = disp;
        });

        // value carries display for each screen width:
        // v-display.block="{xs:1}"
        ["xs", "sm", "md", "lg", "xl"].forEach(size=>{
          if ( value[size] ) {
            $(el).addClass("d-" + size + "-" + defaultDisplay);
          }
        });
        for ( let key in value ){
        }
      }
    });
    */

    // shorthand for :href="'mailto:' + contact"
    Vue.directive('dropdown-toggle', DropdownToggleDirective);

    // mixins
    // ----------------------------------

    Vue.mixin({
      methods: {
        // for dev purposes
        log(...args){ console.log(...args); },
        info(...args){ console.info(...args); },

        // shorthands for central event bus
        appOn(evName, callback){ app.on(evName, callback, evListeningId(this)); },
        // appOnce(evName, callback){ app.once(evName, callback, evListeningId(this)); },
        appOff(evName, callback){ app.off(evName, callback, evListeningId(this)); },
        appEmit(){ app.emit(...arguments); },

        /**
         * convert( defaultUnit, value )
         * Returns value, after converting it into the 
         * preferred units that correspond to provided reference units
         * @param  {String} defaultUnit
         * @param  {Float}  value
         *
         * convert( defaultUnit, value, format )
         * Returns converted value and preferred units (see above),
         * formatted as specified
         * @param  {String} defaultUnit
         * @param  {Float}  value
         * @param  {String} format - see this.format()
         */
        convert(defaultUnit, value, format=null){
          return util.convert(this.actualUnit(defaultUnit), value, format);
        },

        /**
         * Returns preferred units corresponding to provided reference unit
         * @param  {String} defaultUnit
         */
        actualUnit(defaultUnit){
          let unitCategory = util.category(defaultUnit),
              currentUnitIsReference = unitSettings.refUnit.includes(this.$app['units_'+unitCategory])
          ;
          return currentUnitIsReference
            ? defaultUnit
            : util.altUnit(defaultUnit)
          ;
        },

        ascentPerDay(value, short){ return this.convert('m',  value, "+:r:u/d" + (short?'':'ay')) },
        ascent(value)       { return this.convert('m',  value, "+:r:u") },
        distance(value)     { return this.convert('Km', value, ":r :u") },
        speed(value, short) { return this.convert('Km', value, ":r :u/d" + (short?'':'ay')) },
      }, // methods

      filters: { 
        ...utilFilters,

        format(...args){ return util.format(...args) },
      },

      computed: {
        /**
         * Returns current authenticated user, or null
         * @return {Object|null}
         */
        user() {
          return this.$app.appUser || null;
        },

        /**
         * Returns either a user is authenticated
         * @return {Boolean}
         */
        guest() {
          return !this.user;
        },

        /**
         * Returns current authenticated user if he is an admin ;
         * false otherwise
         * @return {Object|nulloolean}
         */
        admin() {
          let user = this.user;
          if ( !user ) return false;
          let checkSum = md5( app.adminSalt + "-" + user.email ); // not really safer, but protects privacy
          return checkSum === app.adminCheck;
        },
      }, // computed

      created(){
        // give reference to the main component (not the root one) of the app
        this.$app = this.$root.$children[0]; 

        // for debug, track every component's creation
        // console.info(`"${this.$options._componentTag}" component created.`);
      }, // created

      beforeDestroy(){
        // remove all of this instance event handlers on the global event bus
        app.off(undefined, undefined, evListeningId(this)); 
      }, // beforeDestroy
    });

  }
};

export default TalariaPlugin;
