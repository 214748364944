/**
 * Cache handler
 */
class Cachette {

    /**
     * @param {Function} keyGetter - optionnal key reference parser, that turns its arguments into a storage key.
     */
    constructor (keyGetter){
        this.storage = {};
        if ( keyGetter instanceof Function ) this.getKey = keyGetter;
    }

    // getter
    get (key, def) {
        return this.storage[ this.getKey(key) ] || def;
    }

    // setter
    set (key, value) {
        this.storage[ this.getKey(key) ] = value;
        return this;
    }

    // returns a storage key, given a reference
    getKey (reference){
        return reference;
    }

};


export default Cachette;
