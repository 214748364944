var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "component-fade", mode: "out-in" } },
    [
      _c(
        "div",
        {
          directives: [{ name: "escapable", rawName: "v-escapable" }],
          staticClass: "overlay center-content",
          on: {
            click: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.close.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [
              _c("button", {
                staticClass: "btn-close float-end",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.close.apply(null, arguments)
                  }
                }
              }),
              _vm._v(" "),
              _vm.waitingComponent ? _c("pre-loader") : _vm._e(),
              _vm._v(" "),
              _c(
                _vm.component,
                _vm._b(
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.waitingComponent,
                        expression: "!waitingComponent"
                      }
                    ],
                    ref: "child",
                    tag: "component",
                    on: { close: _vm.close },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function(ref) {
                          var title = ref.title
                          var role = ref.role
                          return [
                            title
                              ? _c("h3", {
                                  staticClass: "header text-white",
                                  class: ["bg-" + _vm.color(role)],
                                  domProps: { innerHTML: _vm._s(title) }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  },
                  "component",
                  _vm.componentProps,
                  false
                )
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }