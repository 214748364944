// Allows closing dialog windows by pressing "Escape" key

let dialogs = [];

// add a listener on "Escape" key
window.addEventListener("keyup", function(e) {
    if (e.key === "Escape") { // keycode `27`
        let component = dialogs.pop();
        if ( component ){
            component.close 
                ? component.close() 
                : app.debug && console.error("No close methods on component", component)
            ;
        }
    }
});

// export the directive itself
export default {
    bind: function(el, binding, vnode) {
        dialogs.push(vnode.context);
    }
};
