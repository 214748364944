class UrlParser {
    /**
     * Registers a series of new url patterns
     * @param  {Object} patterns.
     */
    constructor(patterns={}){
        this._patterns = patterns;
    }

    /**
     * pattern with name ${label}, passing it
     * provided ${parameters}.
     *
     * @param {String} label - label of url pattern
     * @param {Object} parameters - a hash of parameters names and values
     * @returns {String|undefined}
     */
    parse(label, parameters={}){
        let key,
            pattern = this._patterns[label];
        if ( pattern ) for ( key in parameters ){
            if ( parameters.hasOwnProperty(key) ){
                pattern = pattern.replace(':'+key, parameters[key]);
            }
        }
        return pattern;
    }

    /**
     * Registers a new url pattern
     * @param {String} name    - name of the pattern
     * @param {String} pattern - url pattern, which may include variable names, prefixed with ":" (eg. `:id`)
     * @returns {this}
     */
    add(name, pattern){
        this._patterns[name] = pattern;
        return this;
    }
}

export default UrlParser;
