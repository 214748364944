let util = {};

// General utilities
// =========================================================================

/**
 * Similar to underscore.js's debounce,
 * except that it triggers the callback both
 * a first and last call in the series.
 * @param  {Function} callback
 * @param  {Number}   delay
 * @return {Function} 
 */
util.debounce = function(callback, delay=200){
  let postponeBy = 0, 
      triggerTimeout,
      firstTime = true;

  function trigger(...args){
    if ( !postponeBy ) {
      callback(...args);
      if ( firstTime ){
        postponeBy+=delay;
        firstTime = false;
      }
      else firstTime = true;
    }
    else {
      clearTimeout(triggerTimeout);
      triggerTimeout = setTimeout(function(){
        postponeBy = 0;
        trigger(...args);
      }, postponeBy);
    }
  }
  return trigger;
}
/**
 * Similar to underscore.js's debounce,
 * with more options (see parameters).
 * @param  {Function} callback
 * @param  {Boolean}  immediate    - trigger callback on first call too
 * @param  {Boolean}  progressive  - increase the timeout everytime
 *                                   is could have expired
 * @param  {Number}   initialDelay
 * @return {Function} 
util.debounce = function(callback, immediate=true, progressive=false, initialDelay=200){
  let delay = 0, 
      triggerTimeout,
      incrementTimeout,
      firstCall = true;

  function increaseDelay(){
    if ( progressive ) delay += initialDelay;
    else delay = initialDelay;
    incrementTimeout = setTimeout(()=>increaseDelay(), delay);
  }
  function trigger(...args){
    // initial and final calls
    if ( !delay ) {
      if ( !(!immediate && firstCall) ) callback(...args);

      // on initial call, start timeout increments
      if ( firstCall ){
        increaseDelay()
        firstCall = false;
      }

      // on final call, reset
      else {
        clearTimeout(incrementTimeout);
        firstCall = true;
      }
    }

    // postpones
    else {
      clearTimeout(triggerTimeout);
      triggerTimeout = setTimeout(function(){
        delay = 0;
        trigger(...args);
      }, delay);
    }
  }
  return trigger;
}
 */

// import _ from "underscore"; util.debounce = _.debounce;

/**
 * Converts and return given hash of propertirs and values
 * into a css declaration shape.
 * @param  {Object} hash {property: value}
 * @return {String}      "property: value;"
util.cssify = function(hash) {
    let str = "";
    for ( let key in hash ){
        let value = hash[key];
        str += `${key}: ${value};`;
    }
    return str;
};
 */

/**
 * Returns given querystring as an object
 * @param  {String} str - a query string. Current uri by default.
 * @return {Object}
util.getQueryParameters = function(str) {
    return (str || document.location.search)
        .replace(/(^\?)/,'')
        .split("&")
        .map(function(n){
            return n = n.split("="),
                this[n[0]] = decodeURIComponent(n[1]),
                this
        }.bind({})
    )[0];
};
 */


/**
 * Returns given number, with a leading "0" when it is
 * above 10.
 * @param  {Integer} Number
 * @return {String}
 */
util.pad = function(number) {
    if (number < 10) {
        return '0' + number;
    }
    return number+"";
};


/**
 * Returns four random characters.
 * @return {String}
util.ran4 = function() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
        + ""
    ;
};
 */


/**
 * Rounds ${number}, to ${decimals} decimals.
 * e.g.: util.round(3.1415, 2) => 3.14
 * @param  {Number} number
 * @param  {Number} decimals
 * @return Number
 */
util.round = function(number, decimals=0) {
    let precision = Math.pow(10, decimals) || 1;
    return Math.round(parseFloat(number)*precision) / precision;
};



/**
 * Returns an object, cleaned from all Vue.js watchers
 * @param  {Object} object - object with all Vue.js watchers
 * @return {Object} clone of givent object, with out all Vue.js watchers
 */
util.unvue = function(object){
  if ( !object ) return object;  
  return JSON.parse(
      JSON.stringify(object)
  );
};


/**
 * Returns a copy of provided object,
 * with only provided properties.
 * @param  {Object} source
 * @param  {String} ...props
 * @return {Object}
 */
util.only = function(source, ...props) {
    let copy = {};
    props.forEach(function(prop){
        copy[prop] = source[prop];
    });
    return copy;
};



// App specific data utilities
// =====================================================================

/**
 * Returns a ready-to-print location label.
 * @param  {Object} geocodes
 * @param  {String} basePattern? - default pattern for administration names output ("%l" stands for locality, "%r" for region or state, "%c" for coutry)
 * @param  {String} defaultVal? - default expression in case label couldn't be parsed
 *                                 (sometimes we prefer displaying the coutry flag next to the label)
 * @return {String}
 */
util.labelize = function(geocodes, basePattern="%l, %r", defaultVal="(Mysterious place)") {
    let label = "",
        locality = geocodes.locality,
        region   = geocodes.region,
        country  = geocodes.country
    ;

    if (locality && region) label = basePattern;
    else if ( locality ) label = "%l";
    else if ( region ) label = "Somewhere in %r";
    else label = "%d";

    return label
      .replace("%l", locality)
      .replace("%r", region)
      .replace("%c", country)
      .replace("%d", defaultVal)
    ;
};

/**
 * Calculates the distance between two coordinates, around the globe.
 *
 * @return {Number} The haversine distance between the two points in kilometers
 */
util.haversine = function(lat1, lon1, lat2, lon2) {
  const R = 6371; // Rayon de la Terre en kilomètres

  // Convertir les latitudes et longitudes de degrés en radians
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;

  const a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
  
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance en kilomètres

  return distance;
}

// import more utilities
import unitsUtils from "[util]/units";
Object.assign(util, unitsUtils);

import timeUtils from "[util]/time";
Object.assign(util, timeUtils);

export default util;
