import $ from "jquery";

// misc utilities
import util from 'util';
window.util = util;

// cache handling
import cache from './boot/cache';
app.cache = cache;

// api routing
import apiRouting from './boot/api-router';

// global events bus
import eventsBus from  './boot/events-bus';


// Handle external links
// =============================================================================

$( "body" ).on( "click", "a[href^='http']", function() {
    $( this ).attr( "target", "_blank" );
});

// Font Awesome lib and config
// =============================================================================

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import selectedIcons from "./config/font-awesome-selected-icons";
library.add(...selectedIcons);
dom.watch();

// import vue and app plugin
// =============================================================================

import Vue from 'vue';

import TalariaPlugin from './App/talaria-plugin';
Vue.use(TalariaPlugin);

// import and setup Vue Router
// =============================================================================

import router from "./boot/routing";

// Setup analytics, and get startup function
// =============================================================================

import analytics from "./boot/analytics";
app.analytics = analytics;


// export some data
// =============================================================================

export default { router };
