var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "vue-root" } },
    [
      _c(
        "transition",
        { attrs: { name: "component-fade", mode: "out-in" } },
        [
          _c("main-menu", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showMenu,
                expression: "showMenu"
              }
            ]
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.debug ? _c("dev-console") : _vm._e(),
      _vm._v(" "),
      _vm.showPageLoader
        ? _c(
            "transition",
            { attrs: { name: "component-fade", mode: "out-in" } },
            [
              _c(
                "div",
                { staticClass: "page-preloader center-content" },
                [_c("pre-loader", { attrs: { message: "Loading page" } })],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "component-fade", mode: "out-in" } },
        [
          _vm.configReady && _vm.authReady
            ? _c("router-view", { key: _vm.$route.meta.key })
            : !_vm.showPageLoader
            ? _c("div", { staticClass: "center-content whoops" }, [
                _c("h3", [_vm._v(_vm._s(_vm.defaultErrorMsg))]),
                _vm._v(" "),
                _c("p", [_vm._v("Please try again later")])
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.currentModalDialogComponent
        ? _c("modal-dialog", {
            attrs: {
              component: _vm.currentModalDialogComponent,
              componentProps: _vm.currentModalDialogProps
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.popups.length
        ? _c(
            "ul",
            { staticClass: "pop-ups-container m-0 p-0" },
            _vm._l(_vm.popups, function(popup, index) {
              return _c(
                "pop-up",
                _vm._b(
                  {
                    key: popup.id,
                    on: {
                      close: function($event) {
                        return _vm.popoff(index)
                      }
                    }
                  },
                  "pop-up",
                  popup,
                  false
                )
              )
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }