import Cachette from '../../libs/Cachette';

/**
 * Global cache. Stores anything, given a string identifier.
 * This Object also hosts sub-cache objects.
 * @param  {String} id  - unique identifier
 */
let cache = new Cachette();


/**
 * A helper for reading cache and wraping it in a Promise.
 * Reads a value in cache, and returns either :
 * + a resolved promise if the cache was available
 * + `undefined` otherwise
 * @param  {Cachette} cachetteObject - a cache resolver instance
 * @param  {misc} keyGetter - whatever is used to identify the cached entry
 * @return {$.Deferred|undefined}
 */
cache.cache2deferred = function(cachetteObject, keyGetter){
    let cacheValue = cachetteObject.get(keyGetter);
    if ( cacheValue !== undefined ) { // if data was cached
        return app.apiDeferred().resolve({
            success: true,
            data: cacheValue,
        });
    }
}


/**
 * Step sections cache. Stores route section data (distance, polyline, ...) between two steps,
 * given these steps into an array.
 * @param  {Array|String} steps    - The expected key string, or two StepModel objects from wich the key will be computed
 */
cache.section = new Cachette(function(steps){
    if ( typeof steps === 'string' ) return steps; // handle string keys
    return steps[0].latLngString(true) + "|" + steps[1].latLngString(true);
});


/**
 * Location info cache. Stores steps place geocodes (ie. locality, region and country), elevation, and nearest weather stations.
 * @param  {StepModel|String} step  - The expected key string, or a StepModel object
 */
cache.locationInfo = new Cachette(function(step){
    if ( typeof step === 'string' ) return step; // handle string keys
    return step.latLngString(true);
});


/**
 * Climate cache. Stores climate stats of a weather station,
 * given this station ref.
 * @param  {String} ref
 */
cache.climate = new Cachette();

export default cache;
