<template>
  <transition name="component-fade" mode="out-in">
    <div class="overlay center-content" @click.prevent.stop="close" v-escapable>
      <div class="dialog" @click.stop>

        <button @click.prevent.stop="close" class="btn-close float-end"></button>

        <pre-loader v-if="waitingComponent" />
        <component v-show="!waitingComponent" ref="child" :is="component" v-bind="componentProps" @close="close">
          <template #header="{ title, role }">
            <h3 v-if="title" class="header text-white" :class="['bg-'+color(role)]" v-html="title"></h3>
          </template>
        </component>

      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
  @import "~sass/boot";

  $modal-spacer: map-get($spacers, 3);

  .header {
    margin: -$modal-spacer;
    margin-bottom: $modal-spacer;
    padding: $modal-spacer;
  }

  .close {
    // position: absolute;
    // top: $modal-spacer;
    // right: $modal-spacer;
    position: relative;
    top: 0;
    right: 0;
  }
</style>


<script>
export default {

  name: 'modal-dialog',

  props: ["component", "componentProps"],

  data() {
    return {
      waitingComponent: true, // controls the display of the preloader
    }
  }, // data

  methods:{
    /**
     * Closes the modal
     * @return {void}
     */
    close(){
      this.$app.closeModal();
    },

    /**
     * Returns a Boostrap color name, based on a role name.
     * @param  {String} role? - cheer|warn|inform|error
     * @return {String}
     */
    color(role){
      switch ( role ){
        case "cheer":  return "success";
        case "warn":   return "warning";
        case "inform": return "info";
        case "error":  return "danger";
      }
      return "info";
    },

    /**
     * Repeatingly checks either included component is ready.
     * Hides pre-loader once test is a success. 
     * @return {void}
     */
    waitComponent(){
      if ( this.$refs.child && this.$refs.child._isMounted ){
        this.waitingComponent = false;  
      }
      else {
        this.waitingComponent = setTimeout(this.waitComponent, 100);
      }
    }
  }, // methods

  watch:{
    /**
     * Reactivates pre-loader state.
     * @return {void}
     */
    component(newVal){
      this.$refs.child = null;
      this.waitingComponent = true;  
      this.waitComponent();
    }
  }, // watch

  created(){
    this.waitComponent(); // asks to hide pre-loader when included component is ready
  },

  destroyed(){
    this.waitingComponent = null; // kill timeout
  }
}
</script>
