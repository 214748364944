// Api doc at: https://developer.matomo.org/api-reference/tracking-javascript

let _paq = window._paq = [];

_paq.push(['enableHeartBeatTimer']); // send requests to Matomo every 15sec, in order to better measure the time spent on the page.
// _paq.push(['setDoNotTrack', !app.debug]); // always track during dev
_paq.push(['setDoNotTrack', false]); // always track during dev
_paq.push(['discardHashTag']); // not record the hash tag (anchor) portion of URLs

_paq.push(['disableCookies']);
_paq.push(['deleteCookies']); // discards cookies set on Talaria v2

// tracker methods like "setCustomDimension" should be called before "trackPageView"
_paq.push(['setDownloadClasses', 'is-download']);

// _paq.push(['trackPageView', '[boot]']);
_paq.push(['enableLinkTracking']);

/**
 * Inserts Matomo tracker script in the DOM.
 * See Matomo doc: https://developer.matomo.org/guides/tracking-javascript-guide
 * @param  {String}  trackerUrl - tracker
 * @param  {Integer} siteId
 * @return {void}
 */
function initTracker(trackerUrl, siteId){
    _paq.push(['setTrackerUrl', trackerUrl + app.matomo_tracker_file]);
    _paq.push(['setSiteId', siteId]);
    var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.defer = true;
    g.src = trackerUrl + 'js/'; // default is  'matomo.js', but this works better despite addblockers
    s.parentNode.insertBefore(g, s);
    // console.info("tracker inserted", g, _paq);
};


/**
 * Tracks an event on analytics
 * @param  {...args} args - options to be passed to the tracker (action, name, value, number ; see https://matomo.org/docs/event-tracking/#javascript-trackevent)
 * @return {void}
 */
function trackEvent(...args){
    if ( app.debug ) {
        console.info('trackEvent', [...args]);
    }
    window._paq.push(['trackEvent', ...args]);
}

export default { _paq, initTracker, trackEvent };
