import { render, staticRenderFns } from "./ModalDialog.vue?vue&type=template&id=2c4b1da2&scoped=true&"
import script from "./ModalDialog.vue?vue&type=script&lang=js&"
export * from "./ModalDialog.vue?vue&type=script&lang=js&"
import style0 from "./ModalDialog.vue?vue&type=style&index=0&id=2c4b1da2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c4b1da2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/frameto/WebSites/Talaria/v3/code/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2c4b1da2')) {
      api.createRecord('2c4b1da2', component.options)
    } else {
      api.reload('2c4b1da2', component.options)
    }
    module.hot.accept("./ModalDialog.vue?vue&type=template&id=2c4b1da2&scoped=true&", function () {
      api.rerender('2c4b1da2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "client-src/js/app/App/ModalDialog.vue"
export default component.exports