
const timeUtils = {

  /**
   * Converts given date to a string of given format.
   * If ${date} isn't a proper Date objet, the script will
   * try to make a Date out of it.
   * example of formats:
   *     "yyyy-mm-dd" → "2011-09-02"
   *     "yy-m-d" → "11-9-2"
   *     "mmmm, d" → September, 2
   * @param  {Mixed} date
   * @param  {String} format
   * @return {String}
   */
  date2str(date=null, format="L") {
    let mmm = "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" "),
        mmmm = "January February March Apr!l May June July Augu*T Septe^ber Otober Nove^ber Dece^ber".split(" "); // trick: "^" is used until "m" format has been converted, and "!" for "i", and "*" for "s"

    if ( !date ) date = new Date();
    // if ( date.date ) date = new Date(date.date); // For date that come from Sql DB. Cavehat: the timezone is not taken into account
    if ( !(date instanceof Date) ) date = new Date(date);

    // see https://github.com/abritinthebay/datejs/wiki/Format-Specifiers#tostring-core and http://momentjs.com/docs/#/displaying/ for other good ideas
    return format

        // shorthands
        .replace( "LTS", "H:i:s" )
        .replace( "LT",  "H:i" )
        .replace( "L",   "yyyy-mm-dd" )

        // date
        .replace( "yyyy", date.getFullYear() )
        .replace( "yy",   (date.getFullYear()+"").substring(0,2) )
        .replace( "mmmm", mmmm[date.getMonth()] )
        .replace( "mmm",  mmm[date.getMonth()] )
        .replace( "mm",   util.pad(date.getMonth()+1) )
        .replace( "m",    date.getMonth()+1 )
        .replace( "^",    "m") // used in month names
        .replace( "dd",   util.pad(date.getDate()) )
        .replace( "d",    date.getDate() )

        // time
        .replace( "H",    util.pad(date.getHours()) )
        .replace( "i",    util.pad(date.getMinutes()) )
        .replace( "!",    "i" ) // used in month names
        .replace( "s",    util.pad(date.getSeconds()) )
        .replace( "*",    "s" ) // used in month names
        .replace( "%",    util.pad(date.getMilliseconds()) )
    ;
  },

  /**
  * Given a number of days, returns a number of months and days
  * @param  {Number} days
  * @param  {Boolean} short - either to provide short text (or full)
  * @return {String}
  */
  duration(days, short) {
    // console.info("wip: duration formatting neutralized"); return util.format(days); // uncomment for easier app debugging
    if (!days && days !== 0) return this.format(days);

    let daysPerMonth = 30.4375,
      daysPerYear = 365.25,
      displayWeeks = true,
      years = Math.floor(days / daysPerYear),
      months = Math.floor((days - (years * daysPerYear)) / daysPerMonth),
      weeks = displayWeeks ?
      Math.floor((days - (years * daysPerYear + months * daysPerMonth)) / 7) :
      0;
    days = Math.ceil(days - (years * daysPerYear + months * daysPerMonth + weeks * 7));

    // avoid "7 weeks, 1 day"
    if (days == 7) {
      weeks++;
      days = 0;
    }

    // avoid "4 weeks, 2 days" + 1 day = "1 month, 1 day"
    if (weeks * 7 + days >= Math.floor(daysPerMonth)) {
      months++;
      weeks = 0;
      days = 0;
    }

    let arr = [];
    if (years) arr.push(years + (short ? "y" : " year" + (years > 1 ? "s" : '')));
    if (months) arr.push(months + (short ? "m" : " month" + (months > 1 ? "s" : '')));
    if (weeks) arr.push(weeks + (short ? "w" : " week" + (weeks > 1 ? "s" : '')));
    if (days) arr.push(days + (short ? "d" : " day" + (days > 1 ? "s" : '')));
    return arr.join(short ? "," : ", ") || "-";
  },


  /**
   * Returns how many days ago is provided date
   * @param  {Date|String} date - either a Date instance or a parsable string date
   * @param  {Boolean} short - either to provide short text (or full)
   * @return {String}      
   */
  dateAgo(date, short) {
    if (!(date instanceof Date)) date = new Date(date);
    let daysAgo = Math.round((new Date() - date) / 1000 / 3600 / 24);
    return daysAgo >= 1 
      ? ((short ? "-" : "") + this.duration(daysAgo, short) + (short ? "" : " ago")) 
      : "today"
    ;
  },


  /**
   * Returns a new date, after adding provided number of days to it.
   * ${floorDays} indicate either to round down the number of days, or not.
   * @param {Date}    date - date on which to add days
   * @param {Float}   days - days to be added to date
   * @param {Boolean} [floorDays=true] - either to round down the number of days
   * @return {Date}
   */
  addDaysToDate( date, days, floorDays=true ){
    if ( floorDays ) days = Math.floor( days );
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + days
    );
  },
  

  /**
   * !! Not really working for now. Doesn't support timezones.
   * @param  {Object} phpDateObject - {date, timezone, timezone_type}
   * @return {Date}
   */
  php2jsDate(phpDateObject){
    if ( app.debug ) console.warn("util.php2jsDate doesn't support timezones.");
    return new Date(phpDateObject.date);
  }
}

export default timeUtils;
