import $ from "jquery";

/**
 * A thenable object
 * @constructor
 */
function ApiIo(){
    this.deferred = $.Deferred();
    return this;
}

/**
 * Sends a new request to api, and returns a thenable object.
 * Apart from usual jQuery.ajax arguments, the callbacks also receive the request settings, in a extra argument.
 * @param       {object} data   - same as jQuery.ajax()
 */
ApiIo.prototype.request = function(settings){

    // prequesites for JSON data
    if ( settings.method == "POST" ){
      settings = Object.assign(
        { dataType: 'json', contentType: 'application/json', }, 
        settings, 
        { data: JSON.stringify(settings.data) }
      );
    }
    
    this.ajax = $.ajax(settings)
        .done((...args) => this.deferred.resolve(...args, settings))
        .fail((...args) => this.deferred.reject(...args, settings))
    ;
    return this;
}


/**
 * Sends a request to the server API, with a simplified syntax
 * @param  {String|Array} route - route name or [route name, route parameters]
 * @param  {Object} [data={}] - data to be passed to url parameters, and/or as post data.
 * @return {ApiIo}  a thenable object
 */
 ApiIo.prototype.requestApi = function(route, data={}){

    if ( !Array.isArray(route) ) route = [route];

    // cast parameters into url
    let url = app.urls.parse( route[0], route[1] );
    url = url || "/nowhere"; // missing or empty routes should end up in 404 errors

    // extract method from url pattern (e.g.: `POST /plans/save:id`)
    let method = (url.match( /^(post|get)/i )||[])[0] || "get";
    url = url.replace( /^(post|get)\s+/i, "" );

    // Send XHR and return
    return this.request({method, url, data});
}

/**
 * Some methods are aliases of this.deferred methods.
 * @type {Function}
 */
"resolve reject fail".split(" ").forEach(method=>{
   ApiIo.prototype[method] = function(...args){
      this.deferred[method](...args);
      return this;
   }
});
"state".split(" ").forEach(method=>{
   ApiIo.prototype[method] = function(...args){
      return this.deferred[method](...args);
   }
});


/**
 * Resolves if the request succeeded and the API gives a signal of success
 * @param  {Function} callback
 * @return {this}
 */
ApiIo.prototype.done = function(callback){
    this.deferred.done(function(...args){
        let resp = args[0];
        if ( resp.success ){
            callback.apply(this, [resp.data, ...args]);
        }
    });
    return this;
}

/**
 * Defines handlers for cases of succeeded requests, in which the API gives a signal of error.
 * This methods is *not* an alias of `.fail()`? It actually reacts
 * to successful requests (http 200/30x status) which contain an error message.
 * @param  {Function} callback
 * @return {this}
 */
ApiIo.prototype.error = function(callback) {
    this.deferred.done(function(...args) {
        let resp = args[0];
        if (resp.error) {
            // console.info(this, args);
            callback.apply(this, [resp.error, ...args]);
        }
    });
    return this;
}

/**
 * Defines handlers for cases of failed requests, either due to http error, or http success carrying an error signal.
 * @param  {Function} callback
 * @return {this}
 */
ApiIo.prototype.failures = function(callback){
    this.error(callback);
    this.fail(callback);
    return this;
}


/**
 * Adds handlers for each of success, error, and failure events.
 * @param  {Function} onDone
 * @param  {Function} onError
 * @param  {Function} onFail
 * @return {this}
 */
ApiIo.prototype.then = function(onDone, onError, onFail){
    this.done(onDone);
    if ( onError ) this.error(onError);
    if ( onFail ) this.fail(onFail);
    return this;
}


/**
 * Allways called, either the promise is resolved or rejected
 * @param  {Function} callback
 * @return {this}
 */
ApiIo.prototype.always = function(callback){
    this.then(callback, callback, callback);
    return this;
}

/**
 * Normally called by Queuer.
 * Aborts ajax call.
 */
ApiIo.prototype.abort = function(...args){
  this.ajax.abort(...args);
}

export default ApiIo;
