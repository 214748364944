var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "pop-up alert m-3 alert-opaque",
      class: ["alert-" + _vm.design],
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.cancelTimeout.apply(null, arguments)
        },
        mouseover: _vm.suspendTimeout,
        mouseout: _vm.resetTimeout
      }
    },
    [
      _vm.withTimeout
        ? _c("div", { staticClass: "d-flex mb-2" }, [
            _c("div", { staticClass: "timespent" }),
            _c("div", { ref: "timeleft", staticClass: "timeleft bg-light" })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("button", {
        staticClass: "btn-close float-end ms-2",
        attrs: { type: "button" },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.close.apply(null, arguments)
          }
        }
      }),
      _vm._v(" "),
      _vm.message
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.message && _vm.waitingComponent
        ? _c("pre-loader", { attrs: { message: "" } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        _vm.fullComponentName,
        _vm._b(
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.message && !_vm.waitingComponent,
                expression: "!message && !waitingComponent"
              }
            ],
            ref: "child",
            tag: "component",
            on: { close: _vm.close }
          },
          "component",
          _vm.contentProps,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }